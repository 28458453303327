<template>
  <div>
    <div v-show="!Isbool">
      <el-input size="medium" clearable v-model="Text" style="width: 260px; padding: 0px 10px 0px 10px;" :placeholder="$t('All.料号品名简述模糊搜索')"></el-input>
      <el-button type="primary" icon="el-icon-search" size="small" @click="GetProduct()">{{$t('All.搜索')}}</el-button>
      <el-button type="primary" icon="el-icon-plus" size="small" @click="Isbool = true">{{$t('All.新增')}}</el-button>
    </div>
    <el-table v-show="!Isbool" :data="tableData" style="width: 100%" @row-click="rowclick">
      <el-table-column  type="index" width="50">
      </el-table-column>
      <el-table-column prop="Cdate" :label="$t('All.创建日期')" width="160" align="center">
      </el-table-column>
      <el-table-column prop="Item" :label="$t('All.料号')" align="center">
      </el-table-column>
      <el-table-column prop="Productname" :label="$t('All.品名')" align="center">
      </el-table-column>
      <el-table-column prop="Sketch" :label="$t('All.简述')" align="center">
      </el-table-column>
    </el-table>
    <el-pagination
      style="text-align: center;"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="pageindex"
      :page-sizes="[10, 20, 30, 40]"
      :page-size="page_size"
      layout="total, sizes, prev, pager, next, jumper"
      :total="total"
      hide-on-single-page>
    </el-pagination>
    <Addproductissues v-if="Isbool" :Close="Close"></Addproductissues>
  </div>
</template>
<script>
import { mapMutations } from 'vuex'
export default {
  components: {
    Addproductissues: () => import('./Addproductissues.vue')
  },
  data () {
    return {
      tableData: [],
      Text: '',
      Isbool: false,
      total: 0,
      page_size: 10,
      pageindex: 1
    }
  },
  computed: {},
  watch: {},
  props: ['Addtab', 'RemoveTab', 'Renewtargename', 'Rendw'],
  created () {
    this.GetProduct()
  },
  mounted () { },
  methods: {
    ...mapMutations(['Productnumbersfun']),
    Close () {
      this.Isbool = false
      this.GetProduct()
    },
    async GetProduct () {
      const pgeage = {
        Text: this.Text,
        Pageindex: this.pageindex,
        PageSize: this.page_size
      }
      const { data: res } = await this.$http.post('/api/Product/GetProduct', pgeage)
      if (res.status !== 200) return this.$message.error(res.msg)
      this.tableData = res.response.Product
      this.total = res.response.TotalCount
    },
    handleSizeChange (val) {
      this.page_size = val
      this.GetProduct()
    },
    handleCurrentChange (val) {
      this.pageindex = val
      this.GetProduct()
    },
    rowclick (row) {
      this.Productnumbersfun(row.Numbers)
      this.Addtab(row.Productname, 'Editproductissues')
    }
  }
}

</script>
<style>
.el-upload__tip {
  line-height: 1.2;
}
</style>
